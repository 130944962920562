import IconButton from 'src/components/button/IconButton'
import Dropdown from 'src/components/dropdown/Dropdown'
import { emptyFunction } from 'src/constants/function.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { IChatUser } from 'src/models/messenger.model'
import { handleBlockUser } from 'src/services/messenger.service'

import styles from './styles.module.scss'

enum ChatDropdownTypes {
  toProfile = 'toProfile',
  leaveFeedback = 'leaveFeedback',
  toOffer = 'toOffer',
  blockUser = 'blockUser',
  report = 'report',
  markUnread = 'markUnread',
  pinChat = 'pinChat',
  deleteChat = 'deleteChat',
  help = 'help',
}

const chatHeaderDropdownLocalizePath = 'chat.header.dropdown'

interface ChatHeaderDropdownProps {
  receiverInfo: IChatUser;
  link: string;
  title: string;
  chatId: string;
  receiverId: string;
}

const ChatHeaderDropdown = ({ receiverInfo, link, title, chatId, receiverId }: ChatHeaderDropdownProps) => {
  const chatActions = {
    profile: {
      [`${chatHeaderDropdownLocalizePath}.${ChatDropdownTypes.toProfile}`]: {
        icon: 'profile',
        linkTo: YomeURL.userPage(receiverInfo.id),
      },
    },
    partner: {
      [`${chatHeaderDropdownLocalizePath}.${ChatDropdownTypes.toOffer}`]: {
        icon: 'card-offer-post',
        linkTo: link,
        i18noptions: title,
      },
      // [`${chatHeaderDropdownLocalizePath}.${ChatDropdownTypes.leaveFeedback}`]: {
      //   icon: 'feedback',
      //   onClick: () => console.log('leaveFeedback'),
      // },
      [`${chatHeaderDropdownLocalizePath}.${ChatDropdownTypes.blockUser}`]: {
        icon: 'block',
        onClick: () => handleBlockUser(
          receiverId,
          chatId,
        ),
      },
      [`${chatHeaderDropdownLocalizePath}.${ChatDropdownTypes.report}`]: {
        icon: 'report',
        onClick: () => console.log('report'),
      },
    },
    // chatInterraction: {
    //   [`${chatHeaderDropdownLocalizePath}.${ChatDropdownTypes.markUnread}`]: {
    //     icon: 'unread',
    //     onClick: () => console.log('markUnread'),
    //   },
    //   [`${chatHeaderDropdownLocalizePath}.${ChatDropdownTypes.pinChat}`]: {
    //     icon: 'pin',
    //     onClick: () => console.log('pinChat'),
    //   },
    //   [`${chatHeaderDropdownLocalizePath}.${ChatDropdownTypes.deleteChat}`]: {
    //     icon: 'trash',
    //     onClick: () => console.log('deleteChat'),
    //   },
    // },
    help: {
      [`${chatHeaderDropdownLocalizePath}.${ChatDropdownTypes.help}`]: {
        icon: 'help',
        linkTo: YomeURL.help,
      },
    },
  }

  return (
    <Dropdown
      id={ID.messengerSelectOption}
      sections={chatActions}
      className={styles.dropdown}
    >
      <div>
        <IconButton
          onClick={emptyFunction}
          iconName={'dots-menu'}
          id={ID.messengerToggleChatRoomActions}
        />
      </div>
    </Dropdown>
  )
}

export default ChatHeaderDropdown
